import React, { useState, useContext, useRef } from 'react'
import { graphql, Link, navigate } from 'gatsby'
import styled from 'styled-components'
import { useSpring, animated } from 'react-spring'
import Img from 'gatsby-image/withIEPolyfill'
import { aspectRatio } from '../../helpers/aspect-ratio'
import { TransitionContext } from '../context/transition-context'
import Observer from '../observer'

export const fragmentQl = graphql`
  fragment BlogpageFeaturedFragment on WordPress_Page_Blogpagefields {
    featuredPost {
      post {
        ... on WordPress_Post {
          slug
          title
          blogFields {
            featuredInfos {
              image {
                sourceUrl
                mediaItemId
                modified
                imageFile {
                  childImageSharp {
                    fluid(maxWidth: 2000, quality: 70) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
            }
          }
          blogFilters {
            edges {
              node {
                name
              }
            }
          }
        }
      }
    }
  }
`

const BlogFeaturedPost = ({ featuredPost }) => {
  // eslint-disable-next-line no-unused-vars
  const [transitionActive, setTransitionActive] = useContext(TransitionContext)
  const thePost = featuredPost.post[0]
  const [fadeToggle, setFadeToggle] = useState(false)
  const ref = useRef()

  const fade = useSpring({
    transform: fadeToggle ? `translate3d(0px,0px,0px)` : `translate3d(0,50px,0)`,
    opacity: fadeToggle ? 1 : 0,
    from: { opacity: 0 },
    config: { duration: fadeToggle ? 600 : 0 }
  })

  return (
    <Outer>
      <Observer element={ref.current} toggle={fadeToggle} setToggle={setFadeToggle}>
        <FeaturedPostWrap
          ref={ref}
          to={`/blog/${thePost.slug}`}
          onClick={event => {
            event.preventDefault()
            setTransitionActive(true)
            setTimeout(() => {
              navigate(`/blog/${thePost.slug}`)
              setTransitionActive(false)
            }, 500)
          }}
          style={fade}
        >
          <Image fluid={thePost.blogFields.featuredInfos.image.imageFile.childImageSharp.fluid} />
          <Gradient />
          <WrapTitle>
            <span> Featured News </span>
            <Title
              dangerouslySetInnerHTML={{
                __html: thePost.title
              }}
            />
          </WrapTitle>
        </FeaturedPostWrap>
      </Observer>
    </Outer>
  )
}

const Outer = styled.section`
  position: relative;
  height: auto;
  padding: 5rem 10% 6rem 10%;

  @media (max-width: 1024px) {
    padding: 5rem 0 6rem 0;
  }

  @media (max-width: 650px) {
    padding: 5rem 0 6rem 0;
  }
`

const FeaturedPostWrap = styled(animated(Link))`
  display: block;
  width: 100%;
  position: relative;

  @media (min-width: 1920px) {
  }

  @media (max-width: 650px) {
    height: 300px;
  }
`

const Image = styled(Img)`
  height: 100%;
  ${aspectRatio(1160, 539)}
`

const Gradient = styled.div`
  width: 100%;
  height: 35rem;
  position: absolute;
  bottom: 0;
  left: 0;
  background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
`

const WrapTitle = styled.div`
  position: absolute;
  bottom: 35px;
  left: 45px;

  @media (min-width: 1920px) {
    bottom: 35px;
    left: 50px;
  }

  @media (max-width: 650px) {
    bottom: 15px;
    left: 25px;
  }

  span {
    font-size: 1.3rem;
    text-transform: uppercase;
    font-family: ${props => props.theme.fonts.circular};
    letter-spacing: 0.15rem;
    font-weight: 700;
    display: inline-block;
    margin-bottom: 1.5rem;

    @media (min-width: 1920px) {
      font-size: 1rem;
    }

    @media (max-width: 650px) {
      font-size: 1.75rem;
    }
  }
`

const Title = styled.h3`
  max-width: 55%;
  font-size: 5rem;

  @media (min-width: 1920px) {
    font-size: 4rem;
  }

  @media (max-width: 650px) {
    max-width: 95%;
    font-size: 3.5rem;
    margin-top: 1.5rem;
  }
`

export default BlogFeaturedPost
