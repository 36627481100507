import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { useSpring, animated } from 'react-spring'

import HeroFlowmap from '../hero-flowmap'

export const fragmentQl = graphql`
  fragment BlogpageHeroFragment on WordPress_Page_Blogpagefields {
    hero {
      image {
        sourceUrl
        mediaItemId
        modified
        imageFile {
          publicURL
        }
      }
      imageMobile {
        sourceUrl
        mediaItemId
        modified
        imageFile {
          publicURL
          childImageSharp {
            fluid(maxWidth: 800, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
      title
    }
  }
`

const BlogHero = ({ hero }) => {
  const titleAnimation = useSpring({
    from: { opacity: 0, transform: `translate3d(0px,50px,0px)` },
    to: { opacity: 1, transform: `translate3d(0px,0px,0px)` },
    delay: 1000,
    config: { mass: 5, tension: 500, friction: 100 }
  })

  const textAnimation = useSpring({
    from: { opacity: 0, transform: `translate3d(0px,50px,0px)` },
    to: { opacity: 1, transform: `translate3d(0px,0px,0px)` },
    delay: 1100,
    config: { mass: 5, tension: 500, friction: 100 }
  })

  return (
    <Outer>
      <WrapHero>
        <HeroFlowmap
          mobile={hero.imageMobile.imageFile.childImageSharp.fluid}
          desktop={hero.image.imageFile.publicURL}
        />
        <WrapText>
          <animated.h1 style={titleAnimation}>{hero.title}</animated.h1>
          <animated.p style={textAnimation}>{hero.text}</animated.p>
        </WrapText>
      </WrapHero>
    </Outer>
  )
}

export default BlogHero

const Outer = styled.section`
  position: relative;
  min-height: 100vh;
  overflow: hidden;

  @media (max-width: 1024px) {
    min-height: 90vh;
  }
`

const WrapHero = styled.div`
  position: relative;
  height: 100%;
`

const WrapText = styled.div`
  position: absolute;
  width: calc(40% - 15rem);
  height: auto;
  top: 50%;
  right: 22rem;
  transform: translateY(-50%);

  @media (max-width: 1024px) {
    position: relative;
    top: 0;
    left: 0;
    width: 85%;
    margin: 5rem auto;
    transform: translateY(0);
    text-align: center;
  }

  @media (max-width: 650px) {
    width: 85%;
    top: 5rem;
  }

  h1 {
    color: ${props => props.theme.colours.grey};
    font-family: ${props => props.theme.fonts.sofia};
    font-size: 5rem;
    line-height: 1.15;
    letter-spacing: -0.075rem;
    margin-bottom: 3rem;
    max-width: 18ch;

    @media (max-width: 1024px) {
      font-size: 5rem;
      line-height: 1.25;
      margin-left: auto;
      margin-right: auto;
    }

    @media (max-width: 650px) {
      margin-bottom: 5rem;
    }
  }

  p {
    color: ${props => props.theme.colours.grey};
    font-family: ${props => props.theme.fonts.circular};
    font-weight: 300;
    font-size: 1.6rem;
    line-height: 1.75;
  }
`
