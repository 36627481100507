import React, { useState, useContext, useRef, useEffect } from 'react'
import { graphql, Link, navigate } from 'gatsby'
import styled from 'styled-components'
import { useSpring, useTrail, animated } from 'react-spring'
import Img from 'gatsby-image/withIEPolyfill'
import Observer from '../observer'
import { aspectRatio } from '../../helpers/aspect-ratio'
import { TransitionContext } from '../context/transition-context'
import { ScrollbarContext } from '../context/scrollbar-context'

import Triangle from '../../images/filter_triangle.svg'

export const fragment = graphql`
  fragment BlogListFragment on WordPress_Post_Blogfields {
    featuredInfos {
      title
      image {
        sourceUrl
        mediaItemId
        modified
        altText
        imageFile {
          childImageSharp {
            fluid(maxWidth: 1800, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    }
  }
`

const BlogList = ({ posts, blogFilters, featured }) => {
  // eslint-disable-next-line no-unused-vars
  const [transitionActive, setTransitionActive] = useContext(TransitionContext)
  const [scrollbar] = useContext(ScrollbarContext)
  const [isDesktop, setIsDesktop] = useState(null)
  const [on, toggle] = useState(false)
  const [thisFilter, setFilter] = useState('all')
  const [render, setRender] = useState(0)
  const [animatedContent, setAnimatedContent] = useState(false)
  const [animatedContentChunk, setAnimatedContentChunk] = useState(false)
  const [pageNumber, setPageNumber] = useState(0)
  const [paginationList, setPaginationList] = useState([])
  const [newPaginationList, setNewPaginationList] = useState([0, 1, 2, 3])
  const [currentPage, setCurrentPage] = useState(0)
  const [postPos, setPostPos] = useState(0)
  const ref = useRef()
  const blogBlockRef = useRef()
  const [paginationTriggered, setPaginationTriggered] = useState(false)

  useEffect(() => {
    if (paginationTriggered) {
      setTimeout(() => {
        setAnimatedContentChunk(true)
        setPaginationTriggered(false)
      }, 800)
    }
  }, [paginationTriggered])  

  useEffect(() => {
    if (!animatedContentChunk) {
      setTimeout(() => {
        setAnimatedContentChunk(true)
      }, 400)
    }
  }, [animatedContentChunk])

  const chunk = (arr, size) =>
    Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
      arr.slice(i * size, i * size + size)
    )

  const PostsArrayMinusFeatured = posts.edges.filter(
    post => post.node.title !== featured.post[0].title
  )

  const [blogPostsChunk, setBlogPostsChunk] = useState(chunk(PostsArrayMinusFeatured, 12))

  const animation = useSpring({
    overflow: 'hidden',
    height: on ? '350px' : '0px'
  })

  const animationTriangle = useSpring({
    transform: on ? 'rotate(180deg)' : 'rotate(0deg)'
  })

  const [postsToList, setPostsToList] = useState(PostsArrayMinusFeatured)

  const updatePostsArray = categoryName => {
    const updatedPosts = PostsArrayMinusFeatured.filter(post => {
      // Map over each filter and return true for each hit
      const mappedArr = post.node.blogFilters.edges.map(filter => filter.node.name === categoryName)
      return mappedArr.find(filter => filter === true)
    })
  
    setFilter(categoryName)
    toggle(false)
    setAnimatedContentChunk(false)
  
    setTimeout(() => {
      setPostsToList(updatedPosts)
      setBlogPostsChunk(chunk(updatedPosts, 12))
      setCurrentPage(0)
      renderTheRightPagination(0)
      setRender(render + 1)
    }, 400)
  }
  
  const allProjects = () => {
    setFilter('all')
    toggle(false)
    setAnimatedContentChunk(false)
  
    setTimeout(() => {
      setPostsToList(PostsArrayMinusFeatured)
      setBlogPostsChunk(chunk(PostsArrayMinusFeatured, 12))
      setCurrentPage(0)
      renderTheRightPagination(0)
      setRender(render + 1)
    }, 400)
  }

  const filtersAnimation = useTrail(blogFilters.length, {
    opacity: animatedContent ? 1 : 0,
    x: animatedContent ? 0 : 20,
    from: { opacity: 0, x: 20 },
    config: {
      mass: 8,
      tension: 2500,
      friction: 400
    },
    delay: animatedContent ? 100 : 50
  })

  const blogsAnimation = useTrail(blogPostsChunk[currentPage].length, {
    opacity: animatedContentChunk ? 1 : 0,
    x: animatedContentChunk ? 0 : 20,
    from: { opacity: 0, x: 20 },
    config: {
      mass: 8,
      tension: 4000,
      friction: 250
    },
    delay: animatedContentChunk ? 50 : 5
  })

  const scrollToPosts = () => {
    // const blogPostsTop = blogBlockRef.current.getBoundingClientRect().top
    // console.log(blogPostsTop)
    // if (typeof window !== `undefined`) {
    if (scrollbar && isDesktop) {
      scrollbar.scrollTo(0, postPos - 150, 450)
    } else {
      const topEl = postPos - 50
      window.scrollTo({ top: topEl, behavior: 'smooth' })
    }
    // }
  }

  const renderTheRightPagination = cur => {
    let goodPagination
    const basePagination = paginationList
    setNewPaginationList(paginationList)

    if (cur === 0) {
      goodPagination = basePagination.slice(cur, 4)
      setNewPaginationList(goodPagination)
    } else if (cur === 1) {
      goodPagination = basePagination.slice(cur - 1, cur + 3)
      setNewPaginationList(goodPagination)
    } else if (cur === 2) {
      goodPagination = basePagination.slice(cur - 1, cur + 3)
      setNewPaginationList(goodPagination)
    } else if (cur === basePagination.length - 2) {
      goodPagination = paginationList.slice(cur - 2, cur + 2)
      setNewPaginationList(goodPagination)
    } else if (cur === basePagination.length - 1) {
      goodPagination = paginationList.slice(cur - 3, cur + 1)
      setNewPaginationList(goodPagination)
    } else if (cur > 2) {
      goodPagination = basePagination.slice(cur - 1, cur + 3)
      setNewPaginationList(goodPagination)
    }
  }

  useEffect(() => {
    setPostPos(blogBlockRef.current.getBoundingClientRect().top)
    if (typeof window !== `undefined`) {
      setIsDesktop(window.innerWidth > 1024)
    }
    blogPostsChunk.map((item, index) => {
      if (index < blogPostsChunk.length) {
        setPaginationList(oldArray => [...oldArray, index])
        // setNewPaginationList([0, 1, 2, 3])
      }
    })
    setPageNumber(blogPostsChunk.length)
    setAnimatedContentChunk(true)
    // eslint-disable-next-line
  }, [])

  return (
    <Outer>
      <WrapFilter>
        <FilterTitle onClick={() => toggle(!on)}>
          Filter
          <animated.span style={animationTriangle}>
            <Triangle />
          </animated.span>
        </FilterTitle>
        <WrapFilterMobile style={animation}>
          <FilterButton
            type="button"
            value="all"
            onClick={allProjects}
            className={thisFilter === 'all' ? 'active' : ''}
          >
            All
          </FilterButton>

          {filtersAnimation.map(({ x, ...rest }, index) => {
            const filter = blogFilters[index]
            return (
              <FilterButton
                key={filter.node.name}
                type="button"
                value={filter.node.name}
                onClick={e => {
                  updatePostsArray(e.target.value)
                }}
                className={thisFilter === filter.node.name ? 'active' : ''}
                style={{
                  ...rest,
                  transform: x.interpolate(x2 => `translate3d(0,${x2}px,0)`)
                }}
              >
                {filter.node.name}
              </FilterButton>
            )
          })}
        </WrapFilterMobile>

        <WrapFilterDesktop>
          <FilterButton
            type="button"
            value="all"
            onClick={allProjects}
            className={thisFilter === 'all' ? 'active' : ''}
          >
            All
          </FilterButton>

          {filtersAnimation.map(({ x, ...rest }, index) => {
            const filter = blogFilters[index]
            return (
              <FilterButton
                key={filter.node.name}
                type="button"
                value={filter.node.name}
                onClick={e => {
                  updatePostsArray(e.target.value)
                }}
                className={thisFilter === filter.node.name ? 'active' : ''}
                style={{
                  ...rest,
                  transform: x.interpolate(x2 => `translate3d(0,${x2}px,0)`)
                }}
              >
                {filter.node.name}
              </FilterButton>
            )
          })}
        </WrapFilterDesktop>
      </WrapFilter>

      <BlogBlock ref={blogBlockRef}>
        <Observer element={ref.current} toggle={animatedContent} setToggle={setAnimatedContent}>
          <BlogFeatured ref={ref}>
            {blogsAnimation.map(({ x, ...rest }, index) => {
              const blogItem = blogPostsChunk[currentPage][index]
              return (
                <BlogItem
                  key={blogItem.node.blogFields.featuredInfos.title}
                  to={`/blog/${blogItem.node.slug}`}
                  onClick={event => {
                    event.preventDefault()
                    setTransitionActive(true)
                    setTimeout(() => {
                      navigate(`/blog/${blogItem.node.slug}`)
                      setTransitionActive(false)
                    }, 500)
                  }}
                  style={{
                    ...rest,
                    // eslint-disable-next-line no-shadow
                    transform: x.interpolate(x => `translate3d(0,${x}px,0)`)
                  }}
                >
                  <WrapItem>
                    <WrapBlogImage>
                      <Img
                        style={{ position: 'absolute' }}
                        fluid={
                          blogItem.node.blogFields.featuredInfos.image.imageFile.childImageSharp
                            .fluid
                        }
                        alt={blogItem.node.blogFields.featuredInfos.image.altText}
                      />
                    </WrapBlogImage>
                    <ItemDesc>
                      {blogItem.node.blogFilters.edges.map((cat, i) => (
                        <span key={i}>{cat.node.name} </span>
                      ))}
                      <h2
                        dangerouslySetInnerHTML={{
                          __html: blogItem.node.blogFields.featuredInfos.title
                        }}
                      />
                    </ItemDesc>
                  </WrapItem>
                </BlogItem>
              )
            })}
          </BlogFeatured>
          <Pagination>
            {currentPage > 1 && <p>...</p>}
            {/* {paginationList.map(index => ( */}
            {newPaginationList.map(index => (
              <p
                key={`pagination-${index}`}
                onClick={() => {
                  setPaginationTriggered(true)
                  setAnimatedContentChunk(false)
                  setTimeout(() => {
                    setCurrentPage(index)
                    renderTheRightPagination(index)
                    setRender(render + 1)
                    scrollToPosts()
                  }, 400)
                }}                
                className={currentPage == index ? 'current' : ''}
              >
                {index + 1}
              </p>
            ))}
            {currentPage < paginationList.length - 3 && <p>...</p>}
          </Pagination>
        </Observer>
      </BlogBlock>
    </Outer>
  )
}

const Outer = styled.div`
  position: relative;
  margin: 0 auto;
`

const BlogBlock = styled.div`
  width: 100%;
  margin-bottom: 2.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  position: relative;
`

const WrapFilter = styled.div`
  text-align: center;
  margin: 0 auto 5.5rem auto;
  color: ${props => props.theme.colours.pink};
  z-index: 10;

  @media (max-width: 650px) {
    position: relative;
    width: 87%;
    margin: 0 auto 5rem auto;
    right: unset;
  }
`

const FilterTitle = styled.span`
  display: block;
  color: ${props => props.theme.colours.pink};
  font-family: ${props => props.theme.fonts.sofia};
  font-weight: 400;
  line-height: 2;
  margin-bottom: 2rem;
  display: none;

  @media (max-width: 1024px) {
    display: block;
    font-size: 2.8rem;
  }

  span {
    line-height: 0;
    display: inline-block;
    margin-left: 1rem;
  }
`

const FilterButton = styled(animated.button)`
  background-color: transparent;
  font-family: ${props => props.theme.fonts.sofia};
  font-size: 2rem;
  color: ${props => props.theme.colours.pink};
  padding: 10px 15px;
  border: 0;
  margin-right: 1rem;
  margin-bottom: 2rem;
  border: 2px solid transparent;
  cursor: pointer;
  text-transform: capitalize;

  @media (max-width: 1024px) {
    display: block;
    font-size: 2.8rem;
    padding: 5px 10px;
  }

  &.active {
    border: 2px solid ${props => props.theme.colours.pink};
  }

  &:focus {
    outline: none;
  }
`

const WrapFilterMobile = styled(animated.div)`
  display: none;

  @media (max-width: 1024px) {
    display: block;
    height: auto;
  }
`

const WrapFilterDesktop = styled.div`
  display: block;

  @media (max-width: 1024px) {
    display: none;
  }
`

const BlogFeatured = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 0 auto;
  max-width: 80%;
  width: 100%;

  @media (max-width: 650px) {
    max-width: calc(100% - 50px);
  }
`

const WrapBlogImage = styled.div`
  position: relative;
`

const WrapItem = styled.div`
  width: 100%;

  &:hover h2 {
    color: ${props => props.theme.colours.pink};
  }
`

const ItemDesc = styled.div`
  padding: 2.5rem;
  span {
    color: ${props => props.theme.colours.pink};
    font-family: ${props => props.theme.fonts.circular};
    font-weight: 700;
    letter-spacing: 0.15rem;
    font-size: 1rem;
    text-transform: uppercase;
    margin-bottom: 0.5rem;
    margin-right: 1.5rem;
    line-height: 1.75;

    @media (max-width: 650px) {
      margin-left: 0;
      font-size: 1.75rem;
    }

    &:last-child() {
      margin-right: 0;
    }
  }

  h2 {
    color: ${props => props.theme.colours.grey};
    font-family: ${props => props.theme.fonts.sofia};
    font-size: 2.5rem;
    line-height: 1.33;
    letter-spacing: -0.075rem;
    margin-top: 1.2rem;
    margin-bottom: 0;
    transition: color 0.25s ease;

    @media (max-width: 650px) {
      font-size: 3.15rem;
      margin-top: 2rem;
    }
  }
`

const BlogItem = styled(animated(Link))`
  width: 30%;
  margin-bottom: 6rem;
  border: 1px solid ${props => props.theme.colours.lightGrey};
  margin-right: 5%;
  transition: background-color 0.5s ease;

  &:nth-child(3n) {
    margin-right: 0;
  }

  ${WrapBlogImage} {
    ${aspectRatio(344, 195)}
  }

  @media (max-width: 1024px) {
    width: 47.5%;
    margin-right: 2.5%;
    margin-bottom: 2.5rem;

    &:nth-child(2n) {
      margin-right: 0;
    }

    &:nth-child(3n) {
      margin-right: 2.5%;
    }
  }

  @media (max-width: 750px) {
    width: 100%;
    margin-right: 0;

    &:nth-child(3n) {
      margin-right: 0;
    }
  }

  img {
    transition: transform 0.5s ease !important;
  }

  &:hover {
    background-color: ${props => props.theme.colours.pink};

    & ${ItemDesc} span {
      color: ${props => props.theme.colours.darkGrey};
    }

    & ${ItemDesc} h2 {
      color: ${props => props.theme.colours.white};
    }

    img {
      transform: scale(1.1);
    }
  }
`

const Pagination = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 45rem;
  margin: 0 auto;
  padding: 5rem 0;

  @media (max-width: 750px) {
    padding: 5rem 0;
  }

  p {
    padding: 10px 20px;
    cursor: pointer;

    &:hover {
      background-color: #eee;
    }

    &.current {
      border: 1px solid ${props => props.theme.colours.pink};
    }
  }
`

export default BlogList
