import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import BlogList from '../components/blog/blog'
import BlogHero from '../components/blog/hero'
import BlogFeaturedPostHero from '../components/blog/featured'
import SEO from '../components/seo'

const Blog = ({ data, pageContext }) => (
  <Layout>
    <SEO pageTitle={pageContext.title} slug={pageContext.slug} {...data.wordPress.pageBy.seo} />
    <BlogHero hero={data.wordPress.pageBy.blogpageFields.hero} />
    <BlogFeaturedPostHero featuredPost={data.wordPress.pageBy.blogpageFields.featuredPost} />
    <BlogList
      blogFilters={data.wordPress.blogFilters.edges}
      posts={data.wordPress.posts}
      featured={data.wordPress.pageBy.blogpageFields.featuredPost}
    />
  </Layout>
)

export default Blog

export const query = graphql`
  query($wordpressId: Int!) {
    wordPress {
      pageBy(pageId: $wordpressId) {
        ...SeoFragment
        blogpageFields {
          ...BlogpageHeroFragment
          ...BlogpageFeaturedFragment
        }
      }
      blogFilters(where: { hideEmpty: true }) {
        edges {
          node {
            name
          }
        }
      }
      posts(first: 100) {
        edges {
          node {
            blogFilters {
              edges {
                node {
                  name
                }
              }
            }
            title
            slug
            blogFields {
              ...BlogListFragment
            }
          }
        }
      }
    }
  }
`
